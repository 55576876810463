/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

type QueryData = {
  site: {
    siteMetadata: {
      title: string
      campaignCommittee: string
      fppcID: string
    }
  }
}

const Layout: React.FC<{ children: React.ReactNode; expand?: boolean }> = ({
  children,
  expand,
}) => {
  const data = useStaticQuery<QueryData>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          campaignCommittee
          fppcID
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: expand ? "100%" : `var(--size-content)`,
          padding: expand ? `0px` : `var(--size-gutter)`,
        }}
      >
        <main>{children}</main>
        {data.site.siteMetadata.campaignCommittee ? (
          <footer
            style={{
              marginTop: `var(--space-5)`,
              fontSize: `var(--font-sm)`,
              textAlign: "center",
            }}
          >
            Paid for by Kyle Kelley for Santa&nbsp;Cruz City Schools Board of
            Education Trustee&nbsp;Area&nbsp;2 &middot; FPPC&nbsp;#
            {data.site.siteMetadata.fppcID}
          </footer>
        ) : null}
      </div>
    </>
  )
}

export default Layout
